<template>
  <div class="">
    <div id="components-form-demo-advanced-search">
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleSearch"
      >
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="组织名称：">
              <a-input
                placeholder="请输入组织名称进行查询"
                v-model="deptName"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="创建时间：">
              <a-range-picker :value="pickerValue" @change="onChange" :placeholder="['开始时间','结束时间']"/>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" :style="{ textAlign: 'right' }">
            <a-button type="primary" html-type="submit">
              查询
            </a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
              清除
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <div class="table">
        <a-row>
          <a-col :span="24" :style="{ textAlign: 'left' }">
            <a-button type="primary" html-type="submit" @click="toCreate()">
              新建
            </a-button>
          </a-col>
        </a-row>
        <a-table :columns="columns" :data-source="data" style="margin-top:20px">
          <a slot="name" slot-scope="text">{{ text }}</a>
          <span slot="status">
            <a-switch default-checked />
          </span>
          <span slot="opera" slot-scope="text, record">
            <a-tag
              slot="opera"
              color="green"
              @click="toShow(record)"
              style="cursor: pointer;"
            >
              查看
            </a-tag>
            <a-tag
              slot="opera"
              color="green"
              @click="toEdit(record)"
              style="cursor: pointer;"
            >
              编辑
            </a-tag>
            <a-tag
              slot="opera"
              color="volcano"
              @click="deleteData(record)"
              style="cursor: pointer;"
            >
              删除
            </a-tag>
          </span>
        </a-table>
      </div>
    </div>

    <!-- 删除弹框 -->
    <div>
      <a-modal
        title="提示"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        ok-text="确认"
        cancel-text="取消"
      >
        <p>{{ ModalText }}</p>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { getDeptList, deleteDept } from "@/api/permission/organization/index";
const columns = [
  {
    title: "组织名称 ",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "所属行政区划 ",
    dataIndex: "belong",
    key: "belong"
  },
  {
    title: "组织描述 ",
    key: "org",
    dataIndex: "org"
  },
  {
    title: "创建时间 ",
    key: "time",
    dataIndex: "time"
  },
  {
    title: "操作 ",
    key: "opera",
    dataIndex: "opera",
    scopedSlots: { customRender: "opera" }
  }
];

const data = [
  // {
  //   key: "1",
  //   name: "John Brown",
  //   belong: "heeh",
  //   org: "1",
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "2",
  //   name: "John Brown",
  //   belong: "heeh",
  //   org: "1",
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "3",
  //   name: "John Brown",
  //   belong: "heeh",
  //   org: "1",
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "4",
  //   name: "John Brown",
  //   belong: "heeh",
  //   org: "1",
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "5",
  //   name: "John Brown",
  //   belong: "heeh",
  //   org: "1",
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // },
  // {
  //   key: "6",
  //   name: "John Brown",
  //   belong: "heeh",
  //   org: "1",
  //   time: "17327890909",
  //   opera: ["查看", "编辑", "删除"]
  // }
];

export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: "advanced_search" }),
      data,
      columns,
      ModalText: "确定要删除该组织吗？",
      visible: false,
      confirmLoading: false,

      pickerValue: [],

      deptName: "",
      beginTime: "",
      endTime: "",
      id: 0,
      createdBy:undefined,
    };
  },
  computed: {},
  created() {
    //  验证用户身份
    let admin = localStorage.getItem("admin");
    if (admin == "0") {
      this.createdBy = localStorage.getItem("userName");
    }
    this.getList({
      create_by:this.createdBy
    });
  },
  updated() {
    console.log("updated");
  },
  methods: {
    getList(data) {
      this.data = [];
      getDeptList(data).then(res => {
        console.log(res);
        for (let i in res.data) {
          let data = res.data[i];
          let area = "";
          area +=data.province_name || "";
          area += data.city_name || "";
          area += data.area_name || "";
          this.data.push({
            key: i,
            id: data.dbId,
            name: data.dept_name,
            belong: area,
            org: data.remark,
            time: data.create_time,
            opera: ["查看", "编辑", "删除"]
          });
        }
      });
    },
    // 搜索
    handleSearch(e) {
      e.preventDefault();
      this.form.validateFields((error, values) => {
        console.log("error", error);
        console.log("Received values of form: ", values);
      });
      this.getList({
        dept_name: this.deptName,
        begin_time: this.beginTime,
        end_time: this.endTime
      });
    },
    // 日期选择框
    onChange(date, dateString) {
      this.pickerValue = date;
      this.beginTime = dateString[0];
      this.endTime = dateString[1];
      // console.log(date);
      // console.log(dateString);
    },
    //清除
    handleReset() {
      this.pickerValue = [];
      this.form.resetFields();
      this.beginTime = "";
      this.endTime = "";
      this.deptName = "";
      this.getList();
    },
    //打开删除弹框
    deleteData(value) {
      console.log(value);
      this.id = value.id;
      this.visible = true;
    },
    //确定删除
    handleOk() {
      this.visible = false;
      let _this = this;
      deleteDept({
        deptId: _this.id
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          _this.$message.success("删除成功", function () {
            _this.getList();
          });
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    //取消删除
    handleCancel() {
      this.visible = false;
    },
    //新建/编辑/查看
    toCreate() {
      this.$router.push({
        path: "/index/auth/organization/createOrganization"
      });
    }, //新建/编辑/查看
    toEdit(value) {
      console.log(value);
      this.id = value.id;
      this.$router.push({
        path: "/index/auth/organization/createOrganization",
        query: {
          id: this.id
        }
      });
    }, //新建/编辑/查看
    toShow(value) {
      this.id = value.id;
      this.$router.push({
        path: "/index/auth/organization/createOrganization",
        query: {
          id: this.id,
          show: 1
        }
      });
    }
  }
};
</script>
<style scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #ffffff;
  /* border: 1px solid #d9d9d9; */
  /* border-radius: 6px; */
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}
#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #ffffff;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.table {
  margin-top: 20px;
  background: #ffffff;
  padding: 20px;
}
</style>
